exports.components = {
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-conference-page-js": () => import("./../../../src/templates/conference-page.js" /* webpackChunkName: "component---src-templates-conference-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-directory-page-js": () => import("./../../../src/templates/directory-page.js" /* webpackChunkName: "component---src-templates-directory-page-js" */),
  "component---src-templates-donate-page-js": () => import("./../../../src/templates/donate-page.js" /* webpackChunkName: "component---src-templates-donate-page-js" */),
  "component---src-templates-education-page-js": () => import("./../../../src/templates/education-page.js" /* webpackChunkName: "component---src-templates-education-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-journal-page-js": () => import("./../../../src/templates/journal-page.js" /* webpackChunkName: "component---src-templates-journal-page-js" */),
  "component---src-templates-media-article-page-js": () => import("./../../../src/templates/media-article-page.js" /* webpackChunkName: "component---src-templates-media-article-page-js" */),
  "component---src-templates-media-detailed-page-js": () => import("./../../../src/templates/media-detailed-page.js" /* webpackChunkName: "component---src-templates-media-detailed-page-js" */),
  "component---src-templates-media-page-js": () => import("./../../../src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policy-page.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

